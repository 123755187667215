<template>
    <Header/>
    <main class="content">
      <section class="container mx-auto mt-12 md:mt-20 relative">
        <NuxtLink to="/articles/" class="lg:absolute block text-sm text-black/50 mb-6 flex items-center"><Icon :name="'ArrowLeft'"/>Go back</NuxtLink>
        <div class="max-w-2xl mx-auto mb-10 md:mb-12">
          <h1 class="font-medium text-2xl md:text-4xl mb-8 text-balance">{{ data.data.attributes.title }}</h1>
          <div class="flex text-sm items-center gap-2">
            <div v-if="data?.data?.attributes.employee.data" class="flex items-center gap-2">
              <div class="relative h-5 w-5 rounded-full overflow-hidden bg-black">

                <NuxtPicture v-if="data.data?.attributes.employee.data?.attributes.thumbnail.data?.attributes.hash"
                             loading="eager"
                             preload
                             :src="data?.data?.attributes.employee.data?.attributes.thumbnail.data.attributes.hash"
                             :imgAttrs="{
                                class:'absolute inset-0 object-cover w-full h-full',
                                
                            }"
                />
                <div v-if="!data.data.attributes.employee.data?.attributes.thumbnail.data?.attributes.hash" class="absolute inset-0 w-full h-full flex items-center justify-center">
									<div class="w-3 h-3">
									<svg class="w-full h-full" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M16.7619 31.9999C16.7619 41.2572 9.25732 48.7618 0 48.7618L6.66077e-07 63.9998C17.6563 63.9998 31.9727 49.7002 31.9999 32.0503C32.027 49.7003 46.3434 64 63.9998 64L63.9998 48.7619C54.7424 48.7619 47.2379 41.2574 47.2379 32.0001L16.7619 31.9999Z" fill="white"/>
										<path d="M48.7618 32C48.7618 22.7427 41.2572 15.2382 31.9999 15.2382C22.7426 15.2382 15.2381 22.7426 15.2381 31.9999L6.97341e-05 31.9999C6.89616e-05 14.3269 14.3269 7.72514e-07 32 0C49.673 3.50524e-05 63.9998 14.327 63.9998 32H48.7618Z" fill="white"/>
									</svg>
									</div>
								</div>
              </div>
              <h5>{{ data?.data?.attributes.employee.data.attributes.name }}</h5>
            </div>
            <div>
              <time class="text-black/50" :datetime="data?.data?.attributes.publishedAt">{{ formattedDate(data?.data?.attributes.publishedAt) }}</time>
            </div>
          </div>
        </div>
        <div v-if="data?.data?.attributes.blogImage.data?.attributes.hash" class="w-full h-[400px] md:h-[500px] relative overflow-hidden rounded-lg">
          <div class="absolute top-8 right-8 z-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="120" viewBox="0 0 80 120" fill="none">
              <path d="M39.9997 89.5238C23.1681 89.5238 9.52344 103.168 9.52344 120" stroke="white" stroke-width="19.0476"/>
              <path d="M9.52378 40.0001C9.52378 56.8316 23.1684 70.4762 40 70.4762" stroke="white" stroke-width="19.0476"/>
              <path d="M70.4762 39.9999C70.4762 23.1684 56.8316 9.52376 40 9.52376" stroke="white" stroke-width="19.0476"/>
            </svg>
          </div>
          <NuxtPicture v-if="data?.data?.attributes.blogImage.data?.attributes.hash"
                       
                       loading="lazy"
                       :src="data?.data?.attributes.blogImage.data?.attributes.hash"
                       :imgAttrs="{
                          class:'absolute w-full h-full object-cover',
                          
                       }"
          />
        </div>
      </section>
      <component
          v-for="(block, index) in data?.data?.attributes.body"
          :key="index"
          :index="parseInt(index)"
          :is="getComponentName(block)"
          :data="block"
      />
    </main>
    <div class="container mx-auto mt-20 md:mt-32">
      <div class="h-[1px] bg-gradient-to-r from-transparent via-[#EEF1F2] to-transparent"></div>
    </div>
    <section class="container mx-auto mt-20 md:mt-32">
      <h2>More news</h2>
      <div class="mt-2 grid sm:grid-cols-3 gap-6">
        <article v-for="post in posts">
          <NuxtLink :to="'/' + post.attributes.category.data?.attributes.slug + '/' + post.attributes.slug">
            <div class="w-full h-52 md:h-60 relative rounded-lg overflow-hidden bg-light-grey">
              <NuxtPicture v-if="post?.attributes.blogImage.data?.attributes.hash"
                           
                           loading="lazy"
                           :src="post?.attributes.blogImage.data?.attributes.hash"
                           :imgAttrs="{
                          class:'absolute inset-0 h-full w-full object-cover',
                          
                       }"
              />
            </div>
            <div class="grid gap-6 my-6">
              <h2 class="font-medium text-lg text-balance">{{ post.attributes.title }}</h2>
              <time class="text-black/50 text-sm" :datetime="data?.data?.attributes.publishedAt">
                {{ formattedDate(data?.data?.attributes.publishedAt) }}
              </time>
            </div>
          </NuxtLink>
        </article>
      </div>
    </section>
    <Footer/>
</template>

<script lang="ts">
import componentImports from "../assets/js/blogComponentImports.js";

export default {
  components: {
    ...componentImports,
  },
};
</script>

<script setup lang="ts">
import {useAsyncData, useFetch, useRoute} from "nuxt/app";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Icon from "../components/components/Icon.vue";

const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const { data, pending } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/blog-posts/' + route.meta.documentId + '?pLevel' , {
  headers: {
      "Strapi-Response-Format": "v4",
    },
});
const { data:posts, pending:pendingPosts } = useAsyncData('relatedPosts:' + route.meta.documentId, async () => {
  const posts = await $fetch(runtimeConfig.public.STRAPI_API_URL + '/blog-posts?pLevel=2', {
    headers: {
      "Strapi-Response-Format": "v4",
    },
  });

  const filteredData = posts?.['data'].filter((item) => {
    return item.attributes.category.data.attributes.slug == route.meta.category && item.documentId !== route.meta.documentId;
  });

  return filteredData.slice(0, 3);
});

const seoData = data?.value?.data?.attributes?.seo || {};
const { data: globalSeoData } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/global-seo-setting?pLevel', {
  headers: {
      "Strapi-Response-Format": "v4",
    },
});
const seoDataNoIndex = seoData.noIndex ?? false;
const seoDataTitle = seoData.pageTitle;
const seoDataDescription = seoData.shortDescription;
const seoDataCanonicalUrl = seoData.canonicalURL
const seoDataOgTitle = seoData.ogTitle ?? seoDataTitle
const seoDataOgDescription = seoData.ogDescription  ?? seoDataDescription
const seoDataOgImage = seoData?.image?.data ?? globalSeoData?.value?.data?.attributes?.ogImage.data;
const seoDataOgImageUrl = seoDataOgImage.attributes.url;



useSeoMeta({
  title: () => seoDataTitle,
  ogTitle: () => seoDataOgTitle,
  description: () => seoDataDescription,
  ogDescription:  () => seoDataOgDescription,
  ogImage: () => seoDataOgImageUrl,
})

useHead({
  meta: [
    seoDataNoIndex != false ? { name: 'robots', content: 'noindex, follow' } : {},
  ],
  script: [
    seoData.Schema != null ? { type: 'application/ld+json', innerHTML: seoData.Schema } : {},
    globalSeoData.value.data.attributes.globalSchema != {} ? { type: 'application/ld+json', innerHTML: globalSeoData.value.data.attributes.globalSchema } : {},
  ]
})

const getComponentName = ((block: any) => {
  let blockName = block.__component.replace("blocks.", "");
  return blockName.split('-')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
});

const formattedDate = ((originalDate: any) => {
  const dateObject = new Date(originalDate);
  return dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
});

</script>